import React from 'react'

import loadable from '@loadable/component'
import { Router } from '@reach/router'

const PrivateRoute = loadable(() => import('components/route/PrivateRoute'))
const Admin = loadable(() => import('components/page/admin'))
const Cache = loadable(() => import('components/page/admin/Cache'))
const Dognames = loadable(() => import('components/page/admin/DogNames'))
const Xero = loadable(() => import('components/page/admin/Xero'))
const Apicbase = loadable(() => import('components/page/admin/Apicbase'))
const Shipping = loadable(() => import('components/page/admin/Shipping'))
const Ppd = loadable(() => import('components/page/admin/Ppd'))
const AdminLayout = loadable(() => import('components/ui/layout/AdminLayout'))
const CustomerDelight = loadable(() => import('components/page/admin/CustomerDelight'))

const AdminPanel = () => (
  <AdminLayout>
    <Router basepath="/admin">
      <PrivateRoute path="/" component={Admin} />
      <PrivateRoute path="/cache" component={Cache} />
      <PrivateRoute path="/dognames" component={Dognames} />
      <PrivateRoute path="/xero" component={Xero} />
      <PrivateRoute path="/customerdelight" component={CustomerDelight} />
      <PrivateRoute path="/apicbase" component={Apicbase} />
      <PrivateRoute path="/shipping" component={Shipping} />
      <PrivateRoute path="/ppd" component={Ppd} />
    </Router>
  </AdminLayout>
)

export default AdminPanel
